import { useState } from "react";
import axios from "axios";
import { getFirestore, collection, getDocs, addDoc, serverTimestamp, updateDoc, doc } from 'firebase/firestore';
import { db } from "../firebase"
import { FaArrowRight, FaSpinner } from 'react-icons/fa';
import { IoIosDocument } from 'react-icons/io';
import { IoArrowBackCircleOutline, IoSparklesOutline } from "react-icons/io5";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
const Train = ({ user, privateModels }) => {

    const sortedModels = privateModels.sort((a, b) => b.timestamp - a.timestamp)

    const [sourceDoc, setSourceDoc] = useState(null);
    const [evalDoc, setEvalDoc] = useState(null);
    const [sourceLanguage, setSourceLanguage] = useState("");
    const [targetLanguage, setTargetLanguage] = useState("");
    const [selectedType, setSelectedType] = useState("General");
    const folderName = user?.email;

    const [mainPage, setMainPage] = useState(true)
    const [lab, setLab] = useState(false)
    const [selectedModel, setSelectedModel] = useState(null);

    const goMain = () => {
        setMainPage(true)
        setLab(false)
    }

    const goLab = (model) => {
        setMainPage(false)
        setLab(true)
        setSelectedModel(model)
    }

    const handleSourceDocChange = (e) => {
        setSourceDoc(e.target.files[0]);
    };
    const [swapLanguages, setSwapLanguages] = useState(false);

    const saveRef = collection(db, "models");
    const saveToFirebase = async (fileName) => {
        try {
            await addDoc(saveRef, {
                uid: user.uid,
                folderName: user.email,
                sourceLan: sourceLanguage,
                targetLan: targetLanguage,
                type: selectedType,
                timestamp: serverTimestamp(),
                fileName: fileName,
            });
            alert("Added successfully");
        } catch (error) {
            alert("Saved");
            console.log(error);
        }
    };

    const modelsRef = collection(db, "deployed_models");
    const Publish = async (model) => {
        try {
            await addDoc(modelsRef, {
                uid: model.uid,
                folderName: model.folderName,
                fileName: model.fileName,
                sourceLan: model.sourceLan,
                targetLan: model.targetLan,
                type: model.type,
                timestamp: model.timestamp,
            });
            alert("Published successfully");
        } catch (error) {
            alert("Error");
            console.log(error);
        }
    };

    const handleDeploy = async () => {
        if (!sourceDoc) {
            alert("Please select a JSON file with training data.");
            return;
        }
        if (!sourceLanguage) {
            alert("Please enter the source language name.");
            return;
        }
        if (!targetLanguage) {
            alert("Please enter the target language name.");
            return;
        }
        const timestamp = Date.now();
        const uniqueFileName = `${timestamp}.json`;
        const renamedFile = new File([sourceDoc], uniqueFileName, { type: sourceDoc.type });

        const formData = new FormData();
        formData.append("file", renamedFile);
        formData.append("name", folderName);
        try {
            saveToFirebase(uniqueFileName);
            await axios.post("https://babel-backend.onrender.com/upload", formData);
            console.log("File uploaded successfully");
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const [translation, setTranslation] = useState("");
    const [userQuestion, setUserQuestion] = useState("")

    const getTranslation = async (e, selectedModel) => {
        e.preventDefault();
        try {
            const response = await axios.get("https://babel-backend.onrender.com/translation", {
                params: {
                    userQuestion: userQuestion,
                    folderName: folderName,
                    sourceLan: swapLanguages ? selectedModel?.targetLan : selectedModel?.sourceLan,
                    targetLan: swapLanguages ? selectedModel?.sourceLan : selectedModel?.targetLan,
                    fileName: selectedModel?.fileName,
                },
            });
            setTranslation(response.data.analysis);
        } catch (error) {
            console.error("Error fetching translation:", error);
            setTranslation("An error occurred while fetching the translation.");
        }
    };

    return (
        <>
        {mainPage &&
                <div className="bg-gray-800 sm:px-28 sm:py-8 text-white min-h-screen">
                    <div className="container mx-auto px-4 py-8">
                        <h1 className="text-3xl font-semibold mb-8">A language translator for every single language</h1>
                        <div className="mb-8">
                            <p className="mb-4">
                            </p>
                            <div className="mb-4">
                                <label className="block mb-2">JSON file with training data <span className="text-red-500">*</span></label>
                                <input
                                    type="file"
                                    className="text-white"
                                    onChange={handleSourceDocChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                            <div>
                                <label className="block mb-2">
                                    Domain <span className="text-red-500">*</span>
                                </label>
                                <input
                                    className="w-full bg-gray-700 rounded p-2"
                                    list="domainOptions"
                                    value={selectedType}
                                    onChange={(e) => setSelectedType(e.target.value)}
                                />
                                <datalist id="domainOptions">
                                    <option value="General" />
                                    <option value="Academic" />
                                    <option value="Legal" />
                                    <option value="Environmental" />
                                    <option value="Political" />
                                </datalist>
                            </div>
                            <div>
                                <label className="block mb-2">Source Language Name <span className="text-red-500">*</span></label>
                                <input
                                    className="w-full bg-gray-700 rounded p-2"
                                    value={sourceLanguage}
                                    onChange={(e) => setSourceLanguage(e.target.value)}
                                    required
                                >
                                </input>
                            </div>
                            <div>
                                <label className="block mb-2">Target Language Name <span className="text-red-500">*</span></label>
                                <input
                                    className="w-full bg-gray-700 rounded p-2"
                                    value={targetLanguage}
                                    onChange={(e) => setTargetLanguage(e.target.value)}
                                    required
                                >
                                </input>
                            </div>
                        </div>
                        <div className="flex justify-center py-16">
                            <button
                                className="px-5 py-2 hover:bg-gray-200 rounded font-semibold text-lg text-gray-800 bg-white"
                                onClick={handleDeploy}
                            >
                                Deploy
                            </button>
                        </div>
                    </div>
                    <div className="container mx-auto px-4 py-8">
                        <h1 className="text-2xl font-semibold mb-8">Your translation models</h1>
                    </div>
                    <div className="flex justify-center">
                        {sortedModels ?
                            <div className="grid w-4/5 py-8 grid-cols-3">
                                {sortedModels.map((model, index) => (
                                    <div key={index} className='flex border border-white mx-6 p-6 rounded-xl bg-gray-700 justify-center'>
                                        <div>
                                            <div>
                                                <a className='text-white flex items-center text-sm font-medium'>
                                                {model?.sourceLan} 
                                                    <FaArrowRightArrowLeft className="w-5 h-auto text-white mx-2" /> 
                                                {model?.targetLan}</a>
                                            </div>
                                            <div className="py-5 flex items-center">
                                                <p className='text-white text-sm font-medium'>Domain: {model?.type}</p>
                                            </div>
<div className="grid grid-cols-1 sm:grid-cols-2">
                                                <div className="flex py-3 justify-center">
                                                    <button
                                                        onClick={() => goLab(model)}
                                                        className="px-5 rounded py-2 text-sm border-2 hover:bg-gray-800 border-white text-white font-semibold">Try it out!</button>
                                                </div>
                                                <div className="flex py-3 justify-center">
                                                    <button
                                                        onClick={() => Publish(model)}
                                                        className="px-5 rounded py-2 text-sm border-2 hover:bg-gray-800 border-white text-white font-semibold">
                                                        Publish
                                                    </button>
                                                </div>
</div>
                                        </div>
                                    </div>
                                ))}
                            </div> :
                            <div className="flex my-12 justify-center">
                                <FaSpinner className='animate-spin w-4 sm:w-5 h-4 sm:h-5 sm:ml-2 text-gray-500' />
                            </div>
                        }
                    </div>
                </div>
        }
        {lab && selectedModel && (
                <>
                    <div className="flex justify-center">
                        <div className="w-5/6 py-16">
                            <div className="w-1/2 sm:w-1/6 flex items-center py-6">
                                <a onClick={goMain} className="cursor-pointer flex items-center">
                                    <IoArrowBackCircleOutline
                                        className="w-5 mr-2 h-auto cursor-pointer text-red-500 hover:text-red-600"
                                    />
                                    <p className="text-lg text-blue-600 font-semibold">Go back</p>
                            </a>
                            </div>
                            <div className="w-full flex items-center justify-center">
                                <div className="w-5/12 flex justify-center">
                                    <label className="font-semibold text-lg text-blue-600 py-3" htmlFor="source">
                                        {swapLanguages ? selectedModel?.targetLan : selectedModel?.sourceLan}
                                    </label>
                                </div>
                                <div className="w-1/6 flex justify-center">
                                    <FaArrowRightArrowLeft
                                        className="w-6 mx-6 cursor-pointer hover:text-blue-800 h-auto text-blue-600"
                                        onClick={() => setSwapLanguages(!swapLanguages)}
                                    />
                                </div>
                                <div className="w-5/12 flex justify-center">
                                    <label className="font-semibold text-lg text-blue-600 py-3" htmlFor="source">
                                        {swapLanguages ? selectedModel?.sourceLan : selectedModel?.targetLan}
                                    </label>
                                </div>
                            </div>
                            <div className="grid py-6 grid-cols-2">
                                <div className="flex flex-col justify-center items-center">
                                    <textarea
                                        value={userQuestion}
                                        onChange={(e) => setUserQuestion(e.target.value)}
                                        id="source"
                                        className="w-11/12 text-justify outline-none rounded border-2 border-blue-400 p-3"
                                        rows={16}
                                    />
                                </div>
                                <div className="flex flex-col justify-center items-center">
                                <p className="text-justify"> {translation} </p>
                                </div>
                            </div>
                            <div className="flex justify-center pb-24 pt-6">
                                <button onClick={(e) => getTranslation(e, selectedModel)} className="px-5 py-2 hover:bg-blue-100 font-semibold rounded text-blue-600 border-2 border-blue-600">
                                        Translate
                                    </button>
                            </div>
                        </div>
                    </div>
                </>
        )
        }
</>
    );
};
export default Train;