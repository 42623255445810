import React from "react";
import { IoArrowBackCircleOutline, IoSparklesOutline } from "react-icons/io5";
import { FaArrowRight, FaSpinner } from 'react-icons/fa';

const Models = ({ savedModels }) => {

    const sortedModels = savedModels.sort((a, b) => b.timestamp - a.timestamp)

    return(
<div className="">
            <div className="flex pb-96 justify-center">
                {sortedModels ?
                    <div className="w-5/6 sm:w-3/4 py-8 grid grid-cols-1 sm:grid-cols-3">
                        {sortedModels.map((model, index) => (
                            <div key={index} className='flex border border-gray-100 mx-3 p-6 rounded-xl shadow-xl justify-center'>
                                <div>
                                    <div>
                                        <a className='text-blue-500 flex items-center text-sm font-medium'>{model?.sourceLan}
                                            <FaArrowRight className="w-5 h-auto text-blue-500 mx-2" /> {model?.targetLan}</a>
                                    </div>
                                    <div className="py-5 flex items-center">
                                        <p className='text-gray-800 text-sm font-medium'>Domain: {model?.type}</p>
                                    </div>
                                    <div className="flex py-3 justify-center">
                                        <a
                                            href="mailto:hector.diaz@pucp.edu.pe?subject=Request%20access"
                                            className="px-5 py-2 rounded-lg text-white font-semibold bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800"
                                        >
                                            Request access
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> :
                    <div className="flex my-12 justify-center">
                        <FaSpinner className='animate-spin w-4 sm:w-5 h-4 sm:h-5 sm:ml-2 text-gray-500' />
                    </div>
                }
            </div>
</div>
    )
}

export default Models;