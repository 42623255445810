import { useState, useEffect } from "react";
import {Link} from "react-router-dom" 

const Header = ({ selectedLanguage }) => {
    return (
        <>
            <div className="w-full flex">
                <Link to="/" className="w-full sm:w-1/3 flex items-center py-7 justify-center">
                    <p className="font-semibold text-xl text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-blue-500"> BabelProject</p>
                </Link>
                <div className="hidden sm:flex sm:w-1/3">

                </div>
                <div className="hidden sm:flex sm:w-1/3 items-center flex justify-evenly">
                    <Link to="/gaia" className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-blue-500 cursor-pointer hover:text-blue-800 font-semibold">{selectedLanguage === "es-ES" ? "Gaia" : "Gaia"}</Link>
                    <Link
                        to="/models"
                        className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-blue-500 cursor-pointer hover:text-blue-800 font-semibold"
                    >
                        {selectedLanguage === "es-ES" ? "Modelos" : "Models"}
                    </Link>
                    <Link to="/about" className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-blue-500 cursor-pointer hover:text-blue-800 font-semibold">{selectedLanguage === "es-ES" ? "Acerca de" : "About us"}</Link>
                </div>
            </div>
        </>
    )
}

export default Header;