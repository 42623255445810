import { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { FaGlobeAmericas, FaLaptopCode, FaHandsHelping } from 'react-icons/fa';

const About = ({ selectedLanguage }) => {
    return (
        <>
            <div className="flex justify-center">
                <div className="w-11/12 sm:w-3/4 pt-16 pb-44">
<div className="flex">
                        <p className="text-gray-900 text-justify font-semibold text-xl pt-4 text-center">
                            {selectedLanguage === "es-ES" ? "Los avances en computación en la nube, IA, ML, además de la reducción de los costos de estas tecnologías permitirá conectar a todas las personas, independientemente del idioma que hablen, comunicarse con cualquier persona en el mundo en tiempo real a precios muy accesibles" : "Advances in cloud computing, AI, ML, and the reduction in the costs of these technologies will allow connecting everyone, regardless of the language they speak, to communicate seamlessly in real time with people worldwide and at negligible costs."}
                        </p>
</div>
                    <p className="text-gray-700 text-lg text-justify pt-16 leading-relaxed">
                        {selectedLanguage === "es-ES" ? "La tecnología es la variable de la función de producción que permite expandir la producción más allá de sus límites, el de cero a uno en la economía. Hay personas en todo el planeta que permanecen imposibilitadas de acceder a educación de calidad, medicinas, de usar tecnologías avanzadas y de hacer actividad económica por el hecho de hablar idiomas de bajos recursos, esto hace referencia a idiomas que no tienen un traductor online debido a que no se cuenta con la data suficiente para entrenarlos o no hay personas capacitadas e interesadas en hacerlo." : "Technology is the variable in the production function that allows production to expand beyond its limits, from zero to one in the economy. There are people all over the planet who remain unable to access quality education, medicines, to use advanced technologies, and to engage in economic activity due to the fact that they speak low-resource languages, this refers to languages that do not have an online translator because there is not enough data to train them or there are no interested people, with technical skills, to do so."}
                    </p>
                    <p className="text-gray-700 text-lg text-justify pt-4 leading-relaxed">
                        {selectedLanguage === "es-ES" ? "Para solucionar este problema a nivel global Gaia permite a cualquier persona en el mundo, con acceso a datasets de entrenamiento, entrenar, desplegar y vender los traductores en nuestra plataforma sin la necesidad de saber programar. Esta idea surgió luego de crear las dos primeras versiones del primer traductor español-awajun en el cual me di cuenta de que las personas que hablan estos low-resource languages están dispuestas a colaborar con material textual, los gobiernos están interesados en pagar por utilizar estos traductores, pero las dificultades técnicas siempre se interponen. Así, al usar Gaia no requires conocimientos de programación, aunque si principios teóricos básicos de traducción que se incluyen en nuestro sitio web. Mis mejores deseos y ha hacer del mundo un lugar con más oportunidades para todos." : "To solve this problem globally, Gaia allows anyone in the world, with access to training datasets, to train, deploy, and commercialize translators on our platform without the need for programming or machine learning knowledge. This idea arose after creating the first two versions of the first Spanish-Awajun translator, in which I realized that people who speak these low-resource languages are willing to collaborate with textual material, governments are interested in paying to use these translators, but technical difficulties always get in the way. Thus, the Gaia does not require programming skills, although it does require basic theoretical principles of translation that are included on our website. My best wishes and lets make the world a place with more opportunities for everyone."}
                    </p>
                </div>
            </div>
        </>
    )
}

export default About;