import {useState, useEffect, useRef} from "react"
import { FaArrowAltCircleRight } from "react-icons/fa";
import {Link} from "react-router-dom"
import { IoArrowBackCircleOutline, IoSparklesOutline } from "react-icons/io5";
import { FaArrowRight, FaSpinner } from 'react-icons/fa';
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { HiInformationCircle } from "react-icons/hi";
import Globe from 'react-globe.gl';
import { FaArrowRightArrowLeft } from "react-icons/fa6";

const Homepage = ({ selectedLanguage, user, savedModels }) => {

    const sortedModels = savedModels.sort((a, b) => b.timestamp - a.timestamp)
    const places = [
        {
            name: 'Ashaninka',
            latitude: -11.25,
            longitude: -74.64,
        },
        {
            name: 'Awajun',
            latitude: -4.14,
            longitude: -78.11,
        },
        {
            name: 'Aymara',
            latitude: -15.8402,
            longitude: -70.0219,
        },
    ];

    const globeRef = useRef();
    const [globeReady, setGlobeReady] = useState(false);

    const startTime = 0;

    useEffect(() => {
        if (!globeRef.current) {
            return;
        }
        globeRef.current.pointOfView(
            {
                lat: -11.25,
                lng: -74.64,
                altitude: 2.5,
            },
            startTime
        );
        globeRef.current.controls().autoRotate = true;
        globeRef.current.controls().autoRotateSpeed = 0.2;
    }, [globeReady]);

    return(
        <>
            <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
                <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 py-12">
                        <div className="flex items-center justify-center">
                            <div>
                            <h1 className="text-4xl md:text-6xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-yellow-500">
                                It has started!
                            </h1>
                            <p className="font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-yellow-500 pt-8 text-xl">
                                A language translator for every language through LLMs
                            </p>
                            </div>
                        </div>
                    <div className="flex justify-center items-center">
                        <div className="flex justify-center items-center h-screen">
                            <Globe
                                globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                                backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
                                labelsData={places}
                                labelLat={d => d.latitude}
                                labelLng={d => d.longitude}
                                labelText={d => d.name}
                                labelSize={() => 2}
                                onGlobeReady={() => setGlobeReady(true)}
                                labelDotRadius={() => 1.3}
                                labelColor={() => 'rgba(255, 165, 0, 0.75)'}
                                labelResolution={2}
                                width={600}
                                ref={globeRef}
                                height={600}
                                className="globe-3d"
                                initialZoom={3}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-cover opacity-100 h-screen bg-[url('https://luya.blob.core.windows.net/edux/dark_bg.png')]" >
                <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="pt-56 px-10 sm:pl-24">
                        <p className="text-white font-medium pb-6"> {selectedLanguage === "es-ES" ? "Investigación en ML por Vortex" : "ML research at Vortex" } </p>
                        <p className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-yellow-400 text-3xl pb-6 font-medium"> {selectedLanguage === "es-ES" ? "Comunicación sin fisuras" : "Breaking language barriers"}</p>
                        <p className="text-white font-medium pb-6">
                            {selectedLanguage === "es-ES" ? "Estámos creando una familia de modelos de traducción que permiten una comunicación más natural y auténtica entre diferentes idiomas en todo el Perú" : "Train and deploy your own language translator with no code."}
                        </p>
                        <div className="flex cursor-pointer">
                            <FaArrowAltCircleRight className="w-7 h-auto text-yellow-700 mr-2" />
                            <Link to="/gaia" className="text-transparent bg-clip-text hover:underline bg-gradient-to-r from-orange-600 to-yellow-500 text-lg underline font-semibold"> {selectedLanguage === "es-ES" ? "Entrena y despliega un traductor con a Gaia" : "Start with Gaia"}</Link>
                        </div>
                    </div>
                    <div className="flex justify-center mt-32 items-center">
                        <img src="https://luya.blob.core.windows.net/edux/people.png" className="w-2/3 h-auto" />
                    </div>
                </div>
            </div>


            <div className="flex justify-center">
                <div className="w-5/6 grid pt-20 pb-64 grid-cols-1 sm:grid-cols-2">
                    <div className="flex mx-8 sm:mx-24 justify-center items-center">
                        <p className="text-transparent bg-clip-text bg-gradient-to-r from-orange-600 to-yellow-500 font-semibold text-3xl">Check out the full list of language translators</p>
                    </div>
                    <div className="px-2">
                        {sortedModels ?
                            <div className="w-full py-8 grid grid-cols-1 sm:grid-cols-2">
                                {sortedModels.map((model, index) => (
                                    <div key={index} className='flex border border-gray-100 mx-3 p-6 rounded-xl shadow-xl justify-center'>
                                        <div>
                                            <div className="flex items-center">
                                                <a className='text-blue-500 flex items-center text-sm font-medium'>{model?.sourceLan}
                                                    <FaArrowRightArrowLeft className="w-5 h-auto text-blue-500 mx-2" /> {model?.targetLan}</a>
                                            </div>
                                            <div className="py-5 flex items-center">
                                                <p className='text-gray-800 text-sm font-medium'>Domain: {model?.type}</p>
                                            </div>
                                            <div className="flex py-3 justify-center">
                                                <a
                                                    href="mailto:hector.diaz@pucp.edu.pe?subject=Request%20access"
                                                    className="px-5 py-2 rounded-lg text-white font-semibold bg-gradient-to-r from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-800"
                                                >
                                                    Request access
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> :
                            <div className="flex my-12 justify-center">
                                <FaSpinner className='animate-spin w-4 sm:w-5 h-4 sm:h-5 sm:ml-2 text-gray-500' />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="grid py-44 grid-cols-1 sm:grid-cols-2">
                <div className="flex justify-center sm:justify-end">
                    <img src="https://luya.blob.core.windows.net/edux/last_bg_trans.png" className="w-2/3" />
                </div>
                <div className="sm:pl-12 flex justify-center items-center ">
                <div className="sm:px-10 w-5/6 sm:w-full py-16 sm:py-0">
                        <p className="text-transparent bg-clip-text bg-gradient-to-r from-orange-600 to-yellow-500 font-semibold text-3xl  pb-6"> {selectedLanguage === "es-ES" ? "Yumi v2, el primer traductor entre Awajún y Español" : "Yumi v2, State-Of-The-Art NMT between Spanish and Awajun"}</p>
                        <div className="flex cursor-pointer">
                            <img src="https://cdn-lfs.huggingface.co/repos/96/a2/96a2c8468c1546e660ac2609e49404b8588fcf5a748761fa72c154b2836b4c83/942cad1ccda905ac5a659dfd2d78b344fccfb84a8a3ac3721e08f488205638a0?response-content-disposition=inline%3B+filename*%3DUTF-8%27%27hf-logo.svg%3B+filename%3D%22hf-logo.svg%22%3B&response-content-type=image%2Fsvg%2Bxml&Expires=1715572444&Policy=eyJTdGF0ZW1lbnQiOlt7IkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcxNTU3MjQ0NH19LCJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLWxmcy5odWdnaW5nZmFjZS5jby9yZXBvcy85Ni9hMi85NmEyYzg0NjhjMTU0NmU2NjBhYzI2MDllNDk0MDRiODU4OGZjZjVhNzQ4NzYxZmE3MmMxNTRiMjgzNmI0YzgzLzk0MmNhZDFjY2RhOTA1YWM1YTY1OWRmZDJkNzhiMzQ0ZmNjZmI4NGE4YTNhYzM3MjFlMDhmNDg4MjA1NjM4YTA%7EcmVzcG9uc2UtY29udGVudC1kaXNwb3NpdGlvbj0qJnJlc3BvbnNlLWNvbnRlbnQtdHlwZT0qIn1dfQ__&Signature=JRql9oP9EvScfl314-Q3Y89x2jmrZ5BmjNuAr-fegHZMwHQz5TS3JicO5zkb7m8bsuUoERhcbDxtlfgmjepIm39FJfdVIUa0pSqhFdhoXKIPZeJ5Y0qjsSPkOfnHvY9J1SwZ5q92Ebo2XQvb6rflYUTf9gpO7ScSFhLw47u%7E6TYYf5olZI481u5HMYX0IvX8s52xaLygDgbybKTyfkrw6BAy1F2zo%7EmVRC1ZQeRQb9E5nJ77resi0c1yNBvT4-kUOFL6P-Fc9yVniIbyRLvKxgYfb9olg8TTMDLx4NHzqlaQH4gpeuHeHF%7Er9IMxHR2B1S9sdz%7E3xms6pcMiTctU6Q__&Key-Pair-Id=KVTP0A1DKRTAX" className="w-7 h-auto text-blue-800 mr-2" />
                            <a href="https://huggingface.co/hectordiazgomez/nllb-spa-awa-v2" target="_blank" className="text-gray-500 text-lg underline font-semibold"> 
                            
                            {selectedLanguage === "es-ES" ? "Empieza a usar Yumi v2 desde HuggingFace" : "Start using Yumi v2 from HuggingFace"}</a>
                        </div>
                        <div>
                            <a className="flex text-sm text-gray-400 pt-3 items-center"> <HiInformationCircle className="text-gray-400 mr-1 w-4 h-auto" /> {selectedLanguage === "es-ES" ? "Para desarrolladores" : "For developers"}</a>
                        </div>
            </div>
            </div>
            </div>
            <div className="flex justify-center">
                <div className="grid w-11/12 rounded-xl mb-20 bg-[url('https://luya.blob.core.windows.net/edux/dark_bg.png')] px-2 sm:px-4 grid-cols-1 py-44 sm:grid-cols-2">
                    <div className="flex justify-center">
                        <div className="sm:pl-44 px-10 sm:px-0">
                            <p className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-yellow-400 text-3xl font-medium"> {selectedLanguage === "es-ES" ? "Entrena tu propio traductor" : "Train your own language translator"}</p>
                            <p className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-yellow-400 pt-4 text-3xl font-medium"> {selectedLanguage === "es-ES" ? "Usa Gaia" : "Use Gaia"}</p>
                            <p className="pt-6 text-white text-lg"> {selectedLanguage === "es-ES" ? "Entrena y desplegado tus propios modelos de traducción automática neuronal" : "Train and deploy your own neural machine translation models"}</p>
                            <Link to="/gaia" className="flex cursor-pointer mt-6">
                                <BsArrowUpRightCircleFill className="w-6 h-auto text-yellow-500" />
                                <a className="text-yellow-500 text-lg font-semibold underline ml-1"> {selectedLanguage === "es-ES" ? "Ir Ahora" : "Start Now"}</a>
                            </Link>

                        </div>
                    </div>
                    <div className="flex py-12 sm:py-0 justify-center">
                        <img className="w-2/5 h-auto" src="https://luya.blob.core.windows.net/edux/ml_img.png" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Homepage;