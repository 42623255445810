import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = ({ selectedLanguage }) => {
    return (
        <>
            <div className="flex justify-center bg-gray-200">
                <div className="w-4/5 border-t border-gray-300 pt-2 my-12">
                    <div className="w-1/2 sm:flex justify-evenly">
                        <p className="py-4 sm:py-0"><Link to="/about" className="my-4 cursor-pointer hover:text-gray-600 text-gray-500">
                            {selectedLanguage === "es-ES" ? "Acerca de" : "About"}
                        </Link></p>
<p className="py-4 sm:py-0">
                            <Link to="/gaia" className="my-4 cursor-pointer hover:text-gray-600 text-gray-500">
                                {selectedLanguage === "es-ES" ? "Entrena" : "Train"}
                            </Link>
</p>
                        <p className="py-4 sm:py-0">
                            <a href="https://huggingface.co/hectordiazgomez/nllb-spa-awa-v2" target="_blank" className="my-4 cursor-pointer hover:text-gray-600 text-gray-500">
                                {selectedLanguage === "es-ES" ? "Yumi" : "Yumi"}</a>
</p>
                        <p className="py-4 sm:py-0">
                            <Link className="my-4 cursor-pointer hover:text-gray-600 text-gray-500">
                                {selectedLanguage === "es-ES" ? "Colabora" : "Team with us"}</Link>
</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;