import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"

const Gaia = ({ selectedLanguage, user, signIn}) => {


    const navigate = useNavigate();

    const handleClick = () => {
        if (user) {
            navigate('/train');
        } else {
            signIn();
        }
    };

    return(
        <>
            <div className="w-full bg-cover flex justify-center opacity-100 h-screen bg-[url('https://luya.blob.core.windows.net/edux/dark_bg.png')]">
                    <div className="w-5/6">
                    <div className=" grid grid-cols-1 sm:grid-cols-2 w-full">
                        <div className="py-32 sm:pt-72 pb-24">
                            <p className="text-3xl text-transparent bg-clip-text bg-gradient-to-r from-white to-blue-100 font-medium"> {selectedLanguage === "es-ES" ? "Entrena un traductor sin código" : "Train a language translator with no code"}</p>
                            <p className="text-white text-lg pt-6"> {selectedLanguage === "es-ES" ? "Utiliza Gaia. Estamos rompiendo las barreras del idioma al permitir que cualquier persona en el mundo entrene e implemente un traductor de idiomas sin la necesidad de escribir código." : "Use Gaia. We are breaking the language barriers by allowing anyone worlwide to train, deploy and commercialize language translators with no code"}</p>
                        </div>
                        <div className="flex items-center justify-center">
                            <img className="w-2/3 h-auto" src="https://luya.blob.core.windows.net/edux/vector_nllb.svg" />
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className="w-full flex justify-center pb-12 pt-6">
                        <button
                            onClick={handleClick}
                            className="px-5 py-2 rounded-lg text-white font-semibold bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800"
                        >
                            {selectedLanguage === 'es-ES' ? 'Accede a Gaia' : 'Access Gaia'}
                        </button>
                    </div>
                    </div>
            </div>
            <div className="flex justify-center">
                    <div className="w-5/6 grid grid-cols-1 sm:grid-cols-2">
                    <div className="flex flex justify-center items-center pt-32 pb-16 sm:py-72">
                        <p className="text-3xl font-medium px-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-blue-500">
                            {selectedLanguage === "es-ES" ? "¿Cómo funciona Gaia?" : "How?"}
                        </p>
                    </div>
                    <div className="flex items-center">
                    <div className="w-full border border-gray-100 shadow-2xl rounded p-12">
                            <p className="text-lg font-medium pb-2 text-gray-800">{selectedLanguage === "es-ES" ? <a>1. Carga tu archivo con el corpus de datos en formato JSON<a href="https://luya.blob.core.windows.net/edux/sample_dataset.json" target="_blank" className="text-blue-500 hover:text-blue-600 cursor-pointer px-1">Ver ejemplo</a></a> : <a>1. Upload your file with the data corpus in JSON format. <a href="https://luya.blob.core.windows.net/edux/api_test.csv" target="_blank" className="text-blue-500 hover:text-blue-600 cursor-pointer px-1">Sample doc</a></a> }</p>
                            <p className="text-lg font-medium pb-2 text-gray-800">{selectedLanguage === "es-ES" ? "2. Escribe el nombre de los idiomas a traducir" : "2. Type in the source and target languages. Make sure the target language is the low-resource language and the source language is the high resource language"}</p>
                            <p className="text-lg font-medium pb-2 text-gray-800"> {selectedLanguage === "es-ES" ? "3. Presiona desplegar" : "3. Hit Deploy"}</p>
                            <div className="flex justify-center">
                                <button
                                    onClick={handleClick}
                                    className="px-5 py-2 rounded-lg text-white font-semibold bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 border-2 border-white"
                                >
                                    {selectedLanguage === 'es-ES' ? 'Accede a Gaia' : 'Access Gaia'}
                                </button>
                            </div>
                    </div>
                    </div>
                    </div>
                        </div>
            <div className="flex justify-center">
                <div className="w-11/12 sm:w-5/6">
                    <div className="flex flex justify-center items-center py-20">
                        <p className="text-3xl font-medium px-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-blue-500">
                            {selectedLanguage === "es-ES" ? "Tecnología" : "Tech behind"}
                        </p>
                    </div>
                    <div className="flex pb-36 items-center">
                        <div className="w-full p-3 sm:p-12">
                            <p className="text-gray-700 text-justify">
                                Initially, Gaia leveraged the power of NLLB from Meta, but recent research, such as <a href="https://arxiv.org/abs/2404.13813" target="\_blank" className="text-blue-500 hover:text-blue-700 cursor-pointer">From LLM to NMT: Advancing Low-Resource Machine Translation with Claude</a> and <a target="\_blank" href="https://arxiv.org/abs/2403.16512" className="text-blue-500 hover:text-blue-700 cursor-pointer">LLMs Are Few-Shot In-Context Low-Resource Language Learners</a> have shown the potential of LLMs to translate to and from low-resource languages. Besides, LLMs have expanded their context windows and cloud providers, such as Azure, have started to provide stability to use GPT-4 Turbo API. Given that, now Gaia will rely on two methodologies:
                                <br /><br />
                                1. Adaptive Context: For <a className="text-blue-600 font-medium">extremely low-resource languages</a>. It leverages semantic search, query search and a handful of other algorithms designed to provide the best context to the LLM.
                                <br /><br />
                                2. Fine-tuning (Will be added soon): Recommended for <a className="text-blue-600 font-medium">low-resource and middle resource languages</a>. It updates the weights and biases of the LLM through Q Low-Ranking approximation, QLORA. Large language models can handle languages well if they have been trained on a sufficient amount of data in those languages.
                                <br /><br />
                                Given that the crawled data from the internet used to train LLMs, generally does not include PDFs, it explains why LLMs perform so poorly on extremely low-resource languages because as far as I experienced gathering data for an extremely low-resource language, most of its content is in PDF docs, such as dictionaries.
                                <br /><br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gaia;