import { useState, useEffect } from "react";
import { query, where } from 'firebase/firestore';
import Homepage from "./components/homepage";
import About from "./components/about";
import Header from "./components/header";
import { auth, googleProvider } from "./firebase"
import Footer from "./components/footer";
import { collection, getDocs } from 'firebase/firestore';
import { signInWithPopup } from 'firebase/auth';
import { db } from "./firebase";
import { useAuthState } from 'react-firebase-hooks/auth';
import Gaia from "./components/gaia";
import Models from "./components/models";
import Train from "./components/train";
import ScrollToTop from "./components/scroll";
import Tutorial from "./components/tutorial";
import { BrowserRouter as Router, Routes, Route   } from "react-router-dom";

function App() {

  useEffect(() => {
    document.title = "BabelProject"
  }, [])
  const [user] = useAuthState(auth);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    } else {
    }
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [savedModels, setSavedModels] = useState([]);
  const paperRef = collection(db, "deployed_models");

  useEffect(() => {
    const getModels = async () => {
      const data = await getDocs(paperRef);
      setSavedModels(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
      console.log(savedModels);
      console.log("Models downloaded");
    }
    getModels()
  }, [] ); 


  const dataRef = collection(db, "models");
  const [privateModels, setPrivateModels] = useState([])

  useEffect(() => {
    const getPrivateModels = async () => {
      if (user) {
        const q = query(dataRef, where("uid", "==", user.uid));
        const data = await getDocs(q);
        setPrivateModels(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
        console.log(privateModels);
        console.log("Models downloaded");
      }
    };
    if (user) {
      getPrivateModels();
    }
  }, [user]); 


  const signIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User email signed in:", result?._tokenResponse?.email);
    } catch (error) {
      console.error("Sign-in error:", error);
    }
  };

  return (
    <Router>
      <ScrollToTop />
      <Header selectedLanguage={selectedLanguage} />
      <Routes>
        <Route path="/" element={<Homepage savedModels={savedModels} user={user} selectedLanguage={selectedLanguage} />} />
        <Route path="/gaia" element={<Gaia user={user} signIn={signIn} selectedLanguage={selectedLanguage} />} />
        <Route path="/tutorial" element={<Tutorial selectedLanguage={selectedLanguage} />} />
        <Route path="/about" element={<About selectedLanguage={selectedLanguage} />} />
        <Route path="/models" element={<Models savedModels={savedModels} selectedLanguage={selectedLanguage} />} />
        {user &&
          <Route path="/train" element={<Train privateModels={privateModels} user={user} selectedLanguage={selectedLanguage} />} />
        }
      </Routes>
      <Footer selectedLanguage={selectedLanguage}/>
    </Router>
  );
}

export default App;
