import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAmM0m1ZtPz_RtuB_509ktyXhm-EPCMX_s",
    authDomain: "babel-project-36cc3.firebaseapp.com",
    projectId: "babel-project-36cc3",
    storageBucket: "babel-project-36cc3.appspot.com",
    messagingSenderId: "962636454801",
    appId: "1:962636454801:web:7c690174456e6a9e095830",
    measurementId: "G-WSXTHRK85D"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();